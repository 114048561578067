import React from "react";
// import logo from './logo.svg';
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <h1 className="text-3xl font-header font-bold">Sectrum</h1>
        <div className="text-2xl font-header">
          <div>Cybersecurity and privacy experts.</div>
        </div>
        <p>&nbsp;</p>
        <p className=" text-2xl Font-Courier">info@sectrum.eu</p>
      </header>
    </div>
  );
}

export default App;
